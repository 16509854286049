import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IContactItem, ILocation } from '../models/interfaces';

export const ContactListItem = ({ contact }: { contact: IContactItem }) => {
  const onContactItemPress = (location: ILocation) => {
    window.open(`tel:${location.phoneNumber}`, '_self');
  };
  return (
    <div>
      <div className='p-3 text-xl flex-1 text-start'>{contact.name}</div>
      {contact.locations.map((contactLocation) => (
        <div
          onClick={() => onContactItemPress(contactLocation)}
          className='p-1 px-4 flex-1 flex-wrap hover:cursor-pointer hover:bg-slate-100'
        >
          <div className='flex items-center flex-wrap'>
            <div className='flex flex-col flex-1 min-w-[250px]'>
              <div className='text-l flex-1 text-start'>{contactLocation.address}</div>
              {!!contactLocation.agent && <div className='text-xs flex-1 text-start'>{contactLocation.agent}</div>}
            </div>
            <div className='text-xs pr-3 text-start'>{contactLocation.phoneNumber}</div>
            <FontAwesomeIcon icon={faPhone as any} className='h-6 w-6 text-slate-700' />
          </div>
        </div>
      ))}
    </div>
  );
};
