import { ObservableObject } from "@legendapp/state";
import { For, observer } from "@legendapp/state/react";
import { ExpandableSection } from "../components/ExpandableSection";
import { ManualsListItem } from "../components/ManualsListItem";
import { useManualsContext } from "../contexts/ManualsContext";
import { IListItem } from "../models/interfaces";
import AppFooter from "../components/AppFooter";

export const ManualsScreen = observer(() => {
  const { manuals } = useManualsContext();

  return (
    <div className="h-full flex flex-col">
      <div className="p-4 flex-1">
        <For each={manuals}>
          {(manualGroup) => (
            <ExpandableSection
              title={manualGroup.groupTitle.get()}
              content={() => (
                <div className="divide-y-2 divide-slate-900 rounded overflow-hidden">
                  <For each={manualGroup.pdfs}>
                    {(manual) => (
                      <ManualsListItem
                        key={(manual.peek() as any).id}
                        manual={manual as ObservableObject<IListItem>}
                      />
                    )}
                  </For>
                </div>
              )}
            />
          )}
        </For>
      </div>
      <AppFooter />
    </div>
  );
});
