import { createContext, useContext } from 'react';
import { ContactsContextValue } from './ContactsContextValue';
import { IContactsContextValue } from './IContactsContextValue';

const contactsContext = createContext<IContactsContextValue>(undefined!);

export const useContactsContext = () => {
  const context = useContext(contactsContext);
  if (context === undefined) {
    throw new Error('useContactsContext must be used within a ContactsProvider');
  }
  return context;
};

interface IContactsContextProps {
  children: JSX.Element;
}
export const ContactsContext = (props: IContactsContextProps) => {
  const contextValue = ContactsContextValue();
  return <contactsContext.Provider value={contextValue}>{props.children}</contactsContext.Provider>;
};
