// iCloudStorage will do async storage if on android
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { openDB } from 'idb';

const appLocalStorageKey = 'appLocalStorage';
const appLocalStorageStore = 'appLocalStorage-Store';
class LocalStorageService {
  public getLocalStorageItemAsync = async <T>(key: string): Promise<T | undefined> => {
    const db = await openDB(appLocalStorageStore, 1, {
      upgrade(db) {
        db.createObjectStore(appLocalStorageKey);
      },
    });
    const json = await db.get(appLocalStorageKey, key);
    if (!json) {
      return;
    }
    return json;
  };

  public saveLocalJsonObjectAsync = async (key: string, jsonObject: any) => {
    const db = await openDB(appLocalStorageStore, 1, {
      upgrade(db) {
        db.createObjectStore(appLocalStorageKey);
      },
    });
    await db.put(appLocalStorageKey, jsonObject, key);
  };

  public IsFileDownloaded = async (id: string): Promise<boolean> => {
    try {
      var isDownloaded = await this.getLocalStorageItemAsync(id);
    } catch (ex) {
      //Nothing to do here just don't mark as downloaded.
      console.log('Something when wrong trying to get local download');
    }

    return !!isDownloaded;
  };
}

export const localStorageService = new LocalStorageService();
