import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AppStateContext } from "./contexts/AppStateContext";
import { RootScreen } from "./routes/RootScreen";
import { ErrorScreen } from "./routes/ErrorScreen";
import { ManualsScreen } from "./routes/ManualsScreen";
import {
  loader as manualScreenLoader,
  ManualScreen,
} from "./routes/ManualScreen";
import { VideosScreen } from "./routes/VideosScreen";
import { loader as videoScreenLoader, VideoScreen } from "./routes/VideoScreen";
import { ContactsScreen } from "./routes/ContactsScreen";
import { NewsScreen } from "./routes/NewsScreen";
import { PrivacyPolicy } from "./routes/PrivacyPolicy";
import { HomeScreen } from "./routes/HomeScreen";
import {
  faBook,
  faHome,
  faPhone,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";

export const appNavigation = [
  {
    //Home//
    path: "/",
    index: true,
    icon: <FontAwesomeIcon icon={faHome as IconProp} />,
    element: <HomeScreen />,
  },
  {
    label: "Manuals",
    path: "/manuals",
    icon: <FontAwesomeIcon icon={faBook as IconProp} />,
    element: <ManualsScreen />,
  },
  {
    label: "Videos",
    path: "/videos",
    icon: <FontAwesomeIcon icon={faVideo as IconProp} />,
    element: <VideosScreen />,
  },
  {
    label: "Contacts",
    path: "/contact",
    icon: <FontAwesomeIcon icon={faPhone as IconProp} />,
    element: <ContactsScreen />,
  },
  {
    label: "News",
    path: "/news",
    icon: <FontAwesomeIcon icon={faNewspaper as IconProp} />,
    element: <NewsScreen />,
  },
];

export const appRoutes = [
  {
    label: "Manual",
    path: "/manuals/manual/:id",
    element: <ManualScreen />,
    loader: manualScreenLoader,
  },
  {
    label: "Video",
    path: "/videos/video/:id",
    element: <VideoScreen />,
    loader: videoScreenLoader,
  },
];

const routes = createBrowserRouter([
  {
    path: "/",
    element: <RootScreen />,
    errorElement: <ErrorScreen />,
    children: [...appNavigation, ...appRoutes],
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  // {
  //   path: '/.well-known/apple-app-site-association',
  //   element: JSON.stringify({
  //     applinks: {
  //       details: [
  //         {
  //           appIDs: ['U2WVBB48N8.com.StaheliWest'],
  //           components: [
  //             {
  //               '/': '*',
  //               comment: 'Matches all urls.',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     webcredentials: {
  //       apps: ['U2WVBB48N8.com.StaheliWest'],
  //     },
  //   }),
  // loader: () => window.location.replace('/.well-known/apple-app-site-association.json'),
  // },
]);

function App() {
  return (
    <AppStateContext>
      <RouterProvider router={routes} />
    </AppStateContext>
  );
}

export default App;
