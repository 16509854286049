import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";

interface IExpandableSectionProps {
  title: string;
  content: () => JSX.Element;
}

export const ExpandableSection = (props: IExpandableSectionProps) => {
  return (
    <div>
      <Disclosure defaultOpen={props.title === "Favorites"}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between items-center rounded-lg bg-[#9F111B] px-6 py-3 text-left text-md font-medium text-slate-300 hover:bg-red-700 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
              <span>{props.title}</span>
              <ChevronUpIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-10 w-10 text-slate-300`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-1 pb-3 text-sm text-slate-900 border-none">
              {props.content}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="mt-2" />
    </div>
  );
};
