import { For } from "@legendapp/state/react";
import { ExpandableSection } from "../components/ExpandableSection";
import { VideoListItem } from "../components/VideoListItem";
import { useVideosContext } from "../contexts/VideosContext";
import AppFooter from "../components/AppFooter";

export const VideosScreen = () => {
  const { videos } = useVideosContext();
  return (
    <div className="flex h-full flex-col">
      <div className="p-4 flex-1">
        <For each={videos}>
          {(videoGroup) => (
            <ExpandableSection
              title={videoGroup.groupTitle.get()}
              content={() => (
                <div className="divide-y-2 divide-slate-900 rounded overflow-hidden">
                  <For each={videoGroup.videos}>
                    {(video) => (
                      <div key={(video.peek() as any).key}>
                        <VideoListItem video={video as any} />
                      </div>
                    )}
                  </For>
                </div>
              )}
            />
          )}
        </For>
      </div>
      <AppFooter />
    </div>
  );
};
