import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { ObservableObject } from "@legendapp/state";
import { observer } from "@legendapp/state/react";
import { useVideosContext } from "../contexts/VideosContext";
import { IListItem } from "../models/interfaces";
import { FileListItem } from "./FileListItem";

interface IVideoListItemProps {
  video: ObservableObject<IListItem>;
}

export const VideoListItem = observer((props: IVideoListItemProps) => {
  const videoContext = useVideosContext();
  function onClick() {
    window.open(props.video.Url.get(), "_blank", "noopener,noreferrer");
  }
  return (
    <FileListItem
      icon={faYoutube}
      label={props.video.label.get()}
      onClick={onClick}
      onShare={() =>
        window.open(props.video.Url.get(), "_blank", "noopener,noreferrer")
      }
      isFavorite={props.video.IsFavorite.get()}
      onFavorite={() => videoContext.favoriteVideoAsync(props.video.get())}
    />
  );
});
