import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  IconProp } from "@fortawesome/fontawesome-svg-core";
import AppFooter from "../components/AppFooter";
import { HomeIcons } from "../models/homeIcons";
import { useAppStateContext } from "../contexts/AppStateContext";

export const HomeScreen = () => {
  const navigate = useNavigate();
  const {homeLinks} = useAppStateContext();

  const homeButtons = homeLinks.map(homeButton => {
    return {
      ...homeButton,
      icon: HomeIcons[homeButton.icon as keyof typeof HomeIcons]
    }
  })

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8 h-full flex flex-col justify-between">
      <div className="flex-1 flex flex-col justify-center items-center mx-auto max-w-7xl px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-white text-center mb-12">
          Welcome to the Staheli West App
        </h1>
        <div className="-mx-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 overflow-hidden sm:mx-0 sm:rounded-2xl">
          {homeButtons.map((item, index) => (
            <div
              key={index}
              className="bg-white/10 p-3 sm:p-5 flex justify-center items-center rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <button
                className="w-44 h-32 bg-[#9F111B] text-white rounded flex flex-col justify-center items-center hover:bg-red-00 transition-colors duration-300"
                onClick={() =>
                  item.link.startsWith('http')
                    ? (window.location.href = item.link!)
                    : navigate(item.link)
                }
                aria-label={item.title}
              >
                <FontAwesomeIcon icon={item.icon as IconProp} size='lg' className="p-3" />
                <span>{item.title}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
      <AppFooter />
    </div>
  );
};
