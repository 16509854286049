import { localStorageService } from './LocalStorageService';

const isDealerKey: string = 'localIsDealerKey';
const isDealerResponseKey = 'UserIsActive';
const isNotActiveKey = 'UserIsNotActive';
const isUserNotLoggedIn = 'UserIsNotLoggedIn';
// uncomment this line to use the production host
const host = 'https://dealer.staheliwest.com';
// const devHost = 'https://staheliwest-staged.azurewebsites.net';
// const host = devHost;
class DealerUnlockService {
  public async getIsDealerAsync(): Promise<boolean> {
    var isDealer = await localStorageService.getLocalStorageItemAsync(isDealerKey);

    // We don't need response here this will try to validate with api and set local storage based on that.
    const apiResponse = await this.validateUserIsDealerOnApiAsync();

    if (!apiResponse) {
      const isOnline = window.navigator.onLine;
      // console.log('Net Info: ', isOnline);

      // If no internet connection then return localhost value because we don't want the local storage to be set to false.
      // just in case the user is a dealer and they don't have internet connection.
      if (!isOnline) {
        return (await localStorageService.getLocalStorageItemAsync(isDealerKey)) ?? false;
      } else {
        return false;
      }
    }

    // console.log("Is a Dealer: ", isDealer);
    return isDealer ? true : false;
  }

  public async tryLoginDealerAsync(userName: string, password: string): Promise<boolean> {
    try {
      const response = await fetch(`${host}/Account/MobileLogin?UserNameOrEmail=${userName}&Password=${password}`, {
        method: 'POST',
        credentials: 'include',
      });

      const json: IDealerLoginSuccessResponse = await response.json();
      if (json) {
        await localStorageService.saveLocalJsonObjectAsync(isDealerKey, json.username === userName);
        // console.log('Login Response: ', json);
      }
    } catch (error) {
      console.log('Error: ', error);
      window.alert('Error: There was an error logging in. Verify your login credentials and try again.');
      await localStorageService.saveLocalJsonObjectAsync(isDealerKey, false);
      return false;
    }

    return true;
  }

  public async logoutDealerAsync(): Promise<void> {
    try {
      const response = await fetch(`${host}/Account/MobileLogOff`);
      // console.log('Response: ', response.text);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  public async lockDealerFeaturesAsync() {
    await localStorageService.saveLocalJsonObjectAsync(isDealerKey, false);
  }

  public async validateUserIsDealerOnApiAsync(): Promise<boolean> {
    try {
      const response = await fetch(`${host}/Account/ValidateUserIsActiveOrLogOff`, { credentials: 'include' });
      const isDealerResponse = await response.text();
      // console.log('Response: ', isDealerResponse);
      switch (isDealerResponse) {
        case isDealerResponseKey:
          await localStorageService.saveLocalJsonObjectAsync(isDealerKey, true);
          return true;
        case isNotActiveKey:
          await localStorageService.saveLocalJsonObjectAsync(isDealerKey, false);
          return false;
        case isUserNotLoggedIn:
          await localStorageService.saveLocalJsonObjectAsync(isDealerKey, false);
          return false;
        default:
          await localStorageService.saveLocalJsonObjectAsync(isDealerKey, false);
          return false;
      }
    } catch (error) {
      console.log('Error: ', error);

      const isOnline = window.navigator.onLine;
      // If no internet connection then return localhost value because we don't want the local storage to be set to false.
      // just in case the user is a dealer and they don't have internet connection.
      if (!isOnline) {
        return false;
      }

      await localStorageService.saveLocalJsonObjectAsync(isDealerKey, false);
      return false;
    }
  }
}

export const dealerUnlockService = new DealerUnlockService();

interface IDealerLoginSuccessResponse {
  dealerId: number | null;
  dealerName: string | null;
  username: string;
}

interface IDealerLoginFailureResponse {
  error: string;
}
