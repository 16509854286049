import { useObservable } from '@legendapp/state/react';
import { IVideosContextValue } from './IVideosContextValue';
import { useAppStateContext } from '../AppStateContext';
import { useEffect } from 'react';
import { IListItem, IVideosGroup } from '../../models/interfaces';
import { localStorageService } from '../../services/LocalStorageService';

const favoritesGroupTitle = 'Favorites';
const favoritesGroupId = 'video_favorites';
const localStorageFavoriteManualsKey: string = 'FavoriteVideoIds';

export const VideosContextValue = (): IVideosContextValue => {
  const appState = useAppStateContext();
  const videos = useObservable([] as IVideosGroup[]);

  useEffect(() => {
    loadAsync();
  }, [appState.isDealer]);

  const loadAsync = async () => {
    const videosResponse = await fetch('/api/Videos.json');
    const videosData = await videosResponse.json();
    if (appState.isDealer) {
      const dealerVideosResponse = await fetch('/api/DealerVideos.json');
      const dealerVideosData = await dealerVideosResponse.json();
      _setVideos([...videosData.Videos, dealerVideosData as IVideosGroup]);
    } else {
      _setVideos(videosData.Videos);
    }
    await loadFavoritesAsync();
  };

  const loadFavoritesAsync = async () => {
    let newVideoList = [...videos.peek()];
    const favorites = await localStorageService.getLocalStorageItemAsync<number[]>(localStorageFavoriteManualsKey);
    if (!favorites || favorites.length === 0) {
      return;
    }

    const videosWithoutGroups = getFlattenedListNoDuplicates(newVideoList);
    videosWithoutGroups.forEach((x) => {
      x.IsFavorite = favorites.some((f) => f === x.id);
    });

    if (newVideoList.findIndex((x) => x.groupTitle === favoritesGroupTitle) === -1) {
      const favoriteVideos = videosWithoutGroups.filter((x) => x.IsFavorite);
      const favoritesGroup = _createFavoritesGroup(favoriteVideos);
      if (favoritesGroup.videos.length > 0) {
        newVideoList = [...newVideoList, favoritesGroup];
      }
    }

    //Need to update the observable
    _setVideos(newVideoList);
  };

  const favoriteVideoAsync = async (video: IListItem) => {
    const isFavorite = !video.IsFavorite;

    videos.forEach((group) => {
      group.videos.forEach((x) => {
        if (x.Url.peek() === video.Url) {
          x.IsFavorite.set(isFavorite);
        }
      });
    });

    if (isFavorite) {
      _addToFavoritesGroup(video);
    } else {
      _removeFromFavoritesGroup(video);
    }

    await _saveFavoritesAsync();
  };

  const _addToFavoritesGroup = (video: IListItem) => {
    const favoritesIndex = videos.findIndex((x) => x.groupTitle === favoritesGroupTitle);
    const favoritesGroup = videos[favoritesIndex];
    if (favoritesIndex === -1) {
      _setVideos([...videos, _createFavoritesGroup([video])]);
    } else {
      favoritesGroup.videos.set([...favoritesGroup.videos.peek(), video]);
    }
  };

  const _removeFromFavoritesGroup = (video: IListItem) => {
    const favoritesIndex = videos.findIndex((x) => x.groupTitle === favoritesGroupTitle);

    if (favoritesIndex === -1) {
      //This should never happen.
      return;
    }

    const favoritesGroup = videos[favoritesIndex];
    const filterFavorites = favoritesGroup.videos.peek().filter((x) => x.Url !== video.Url);
    favoritesGroup.videos.set(filterFavorites);

    if (filterFavorites.length === 0) {
      _setVideos(videos.peek().filter((x) => x.groupTitle !== favoritesGroupTitle));
      return;
    }
  };

  const _saveFavoritesAsync = async () => {
    const favoriteIds =
      videos
        .peek()
        .find((x) => x.groupTitle === favoritesGroupTitle)
        ?.videos.map((x) => x.id) ?? [];
    await localStorageService.saveLocalJsonObjectAsync(localStorageFavoriteManualsKey, favoriteIds);
  };

  const _createFavoritesGroup = (videos: IListItem[]): IVideosGroup => {
    return { id: favoritesGroupId, key: 6, groupTitle: favoritesGroupTitle, videos: videos };
  };

  const _setVideos = (newList: IVideosGroup[]) => {
    const sorted = newList.sort((a, b) => (a.key > b.key ? 1 : -1));
    videos.set(sorted);
  };

  return {
    videos,
    favoriteVideoAsync,
  };
};

const getFlattenedListNoDuplicates = (groupedManuals: IVideosGroup[]): IListItem[] => {
  return [...new Set(groupedManuals.reduce<IListItem[]>((videos, item) => [...videos, ...item.videos], []))];
};
