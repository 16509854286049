import { createContext, useContext } from 'react';
import { IManualsContextValue } from './IManualsContextValue';
import { ManualsContextValue } from './ManualsContextValue';

const manualsContext = createContext<IManualsContextValue>(undefined!);

export const useManualsContext = () => {
  const context = useContext(manualsContext);
  if (context === undefined) {
    throw new Error('useManualsContext must be used within a ManualsProvider');
  }
  return context;
};

interface IAppStateContextProps {
  children: JSX.Element;
}
export const ManualsContext = (props: IAppStateContextProps) => {
  const contextValue = ManualsContextValue();
  return <manualsContext.Provider value={contextValue}>{props.children}</manualsContext.Provider>;
};
