import { IContactsGroup } from './../../models/interfaces';
import { useObservable } from '@legendapp/state/react';
import { IContactsContextValue } from './IContactsContextValue';
import { useEffect } from 'react';
import { useAppStateContext } from '../AppStateContext';

// const Contacts = fetch('/api/Contacts.json');

export const ContactsContextValue = (): IContactsContextValue => {
  const appState = useAppStateContext();
  const contacts = useObservable([] as IContactsGroup[]);

  useEffect(() => {
    loadAsync();
  }, [appState.isDealer]);

  const loadAsync = async () => {
    const contactsResponse = await fetch('/api/Contacts.json');
    const contactsData = await contactsResponse.json();
    contacts.set(contactsData);
    if (appState.isDealer) {
      const dealerContactsResponse = await fetch('/api/DealerContacts.json');
      const dealerContactsData = await dealerContactsResponse.json();
      contacts.set([...contacts.peek(), dealerContactsData[0] as unknown as IContactsGroup]);
    }
  };

  return { contacts };
};
