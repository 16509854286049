import { For, observer } from "@legendapp/state/react";
import { ContactListItem } from "../components/ContactListItem";
import { ExpandableSection } from "../components/ExpandableSection";
import { useContactsContext } from "../contexts/ContactsContext";
import AppFooter from "../components/AppFooter";

export const ContactsScreen = observer(() => {
  const { contacts } = useContactsContext();
  return (
    <div className="flex h-full flex-col">
      <div className="p-4 flex-1">
        <For each={contacts}>
          {(contactGroup) => (
            <ExpandableSection
              title={contactGroup.groupTitle.get()}
              content={() => (
                <div className="border rounded bg-white shadow-md">
                  <For each={contactGroup.group as any}>
                    {(contact) => (
                      <div>
                        <ContactListItem contact={contact.get() as any} />
                        {contacts.peek().indexOf(contact as any) + 1 !==
                          contactGroup.group.length && (
                          <div className="border-b-2" />
                        )}
                      </div>
                    )}
                  </For>
                </div>
              )}
            />
          )}
        </For>
      </div>
      <AppFooter />
    </div>
  );
});
