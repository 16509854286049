import { createContext, useContext } from 'react';
import { INewsContextValue } from './INewsContextValue';
import { NewsContextValue } from './NewsContextValue';

const newsContext = createContext<INewsContextValue>(undefined!);

export const useNewsContext = () => {
  const context = useContext(newsContext);
  if (context === undefined) {
    throw new Error('useNewsContext must be used within a NewsProvider');
  }
  return context;
};

interface INewsContextProps {
  children: JSX.Element;
}
export const NewsContext = (props: INewsContextProps) => {
  const contextValue = NewsContextValue();
  return <newsContext.Provider value={contextValue}>{props.children}</newsContext.Provider>;
};
