import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { faShareSquare, faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

interface IFileListItemProps {
  linkPath?: string;
  icon: IconDefinition;
  label: string;
  isFavorite: boolean;
  onClick?: () => void;
  onShare?: () => void;
  onFavorite?: () => void;
}

export const FileListItem = (props: IFileListItemProps) => {
  const ListItem = () => {
    return (
      <div className="p-1 flex flex-col hover:cursor-pointer hover:bg-slate-600/40 bg-black/40">
        <div className="p-.75 flex items-center justify-between">
          <FontAwesomeIcon
            icon={props.icon as any}
            className="h-6 w-6 text-red-500"
          />
          <div className="p-1" />

          <div className="text-slate-400 text-lg flex-1 text-start pr-5">
            {props.label}
          </div>
          {props.onShare && (
            <div
              onClick={(event) => {
                event.stopPropagation();
                props.onShare?.();
              }}
              className="hover:cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faShareSquare as any}
                className="h-6 w-6 p-3 text-slate-400 hover:text-slate-700"
              />
            </div>
          )}
          <div
            onClick={(event) => {
              event.stopPropagation();
              props.onFavorite?.();
            }}
            className="hover:cursor-pointer"
          >
            <FontAwesomeIcon
              icon={props.isFavorite ? faStarSolid : (faStar as any)}
              className="h-6 w-6 p-3 text-slate-300 hover:text-slate-700"
            />
          </div>
        </div>
      </div>
    );
  };

  if (props.linkPath) {
    return (
      <Link to={props.linkPath}>
        <ListItem />
      </Link>
    );
  }

  return (
    <div onClick={() => props.onClick?.()}>
      <ListItem />
    </div>
  );
};
