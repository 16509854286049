import { faBlog, faBook, faHandHoldingUsd, faNewspaper, faPen, faPhone, faPhotoVideo, faTshirt } from "@fortawesome/free-solid-svg-icons";

export const HomeIcons = {
    Book: faBook,
    Video: faPhotoVideo,
    Phone: faPhone,
    News: faNewspaper,
    Tshirt: faTshirt,
    Pencil: faPen,
    Dollar: faHandHoldingUsd,
    Blog: faBlog,
}