import { IListItem } from '../models/interfaces';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { FileListItem } from './FileListItem';
import { useNavigate } from 'react-router-dom';
import { useManualsContext } from '../contexts/ManualsContext';
import { ObservableObject } from '@legendapp/state';
import { observer } from '@legendapp/state/react';

interface IManualsListItemProps {
  manual: ObservableObject<IListItem>;
}
export const ManualsListItem = observer((props: IManualsListItemProps) => {
  const navigate = useNavigate();
  const manualContext = useManualsContext();

  const onFavorite = () => {
    manualContext.favoriteManualAsync(props.manual.peek());
  };

  const onClick = () => {
    if (isIOS()) {
      window.open(props.manual.Url.get(), '_blank', 'noopener,noreferrer');
      return;
    }
    navigate(`/manuals/manual/${props.manual.key.get()}`);
  };

  return (
    <FileListItem
      icon={faFilePdf}
      label={props.manual.label.get()}
      isFavorite={props.manual.IsFavorite.get()}
      onClick={onClick}
      onShare={() => window.open(props.manual.Url.get(), '_blank', 'noopener,noreferrer')}
      onFavorite={onFavorite}
    />
  );
});

function isIOS(): boolean {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}
