import { useLoaderData } from 'react-router-dom';
import { useManualsContext } from '../contexts/ManualsContext';
import { IListItem } from '../models/interfaces';
import { observer } from '@legendapp/state/react';

interface IManualScreenProps {}

export async function loader({ params }: { params: any }) {
  return { id: params.id };
}

export const ManualScreen = observer((props: IManualScreenProps) => {
  const loaderData: any = useLoaderData();
  const { manuals } = useManualsContext();
  const pdfs = manuals.get().reduce((acc, m) => [...acc, ...m.pdfs], [] as IListItem[]);
  const manual = pdfs.find((m) => m.key == loaderData.id);

  return <iframe title='manual' src={manual?.Url} className='h-full w-full' />;
});
