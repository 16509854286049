import { ReactElement } from 'react';
import { useAppStateContext } from '../contexts/AppStateContext';

const successCount = 4;
var dealerLoginTapCount = 0;
var dealerTapTimeout: any;

interface IDealerUnlockButtonProps {
  children?: ReactElement;
}

export const DealerUnlockButton = (props: IDealerUnlockButtonProps) => {
  const appState = useAppStateContext();

  const checkNeedToShowLogin = async () => {
    if (successCount <= dealerLoginTapCount) {
      dealerLoginTapCount = 0;
      appState.setIsDealerLoginModalOpen(true);
      return;
    }

    clearTimeout(dealerTapTimeout);
    dealerLoginTapCount++;
    dealerTapTimeout = setTimeout(() => {
      dealerLoginTapCount = 0;
    }, 3000);
  };
  return <div onClick={checkNeedToShowLogin}>{props.children}</div>;
};
