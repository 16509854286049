export const PrivacyPolicy = () => {
  return (
    <div>
      <div className='p-4 text-2xl font-bold border-b-2 border-stone-300'>Staheli West Privacy Policy</div>

      <div className='p-4'>
        <div className='text-xl pb-2'> Collection of Information</div>
        <div>
          <p>• Staheli West Inc. uses Google Analytics to track user behavior.</p>
          <p>• The data collected from Google Analytics is stored in Firebase Console by Google.</p>
          <p>
            • Staheli West Inc. uses this data to determine which users own which machines. Notifications are then
            targeted towards a specific audience rather than sending notifications to all app users.
          </p>
        </div>
      </div>
    </div>
  );
};
