import { createContext, useContext } from 'react';
import { IVideosContextValue } from './IVideosContextValue';
import { VideosContextValue } from './VideosContextValue';

const videoContext = createContext<IVideosContextValue>(undefined!);

export const useVideosContext = () => {
  const context = useContext(videoContext);
  if (context === undefined) {
    throw new Error('useVideosContext must be used within a VideosProvider');
  }
  return context;
};

interface IVideosContextProps {
  children: JSX.Element;
}
export const VideosContext = (props: IVideosContextProps) => {
  const contextValue = VideosContextValue();
  return <videoContext.Provider value={contextValue}>{props.children}</videoContext.Provider>;
};
