import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

let registrationGlobal: ServiceWorkerRegistration | undefined;

const UpdateAvailable = () => {
  return (
    <div id='updateToast' className='hidden absolute bottom-0 right-0'>
      <div className=' bg-yellow-100 border-l-4 border-yellow-500 text-yellow-900 p-4 m-4' role='alert'>
        <p className='font-bold'>App Update Available</p>
        <p>There is a new version of the app available.</p>
        <button
          className='text-blue-400 justify-end'
          onClick={async () => {
            const updateToast = document.getElementById('updateToast');
            registrationGlobal?.waiting?.postMessage({ type: 'SKIP_WAITING' });
            // eslint-disable-next-line no-restricted-globals
            location.reload();
            if (updateToast) {
              updateToast.classList.add('hidden');
            }
          }}
        >
          Update Now
        </button>
      </div>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
    <UpdateAvailable />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const updateToast = document.getElementById('updateToast');
    if (!updateToast) {
      return;
    }
    updateToast.classList.remove('hidden');
    registrationGlobal = registration;
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
