import { INewsContextValue } from './INewsContextValue';
// import News from './../../assets/NewsFeed.json';
import { useObservable } from '@legendapp/state/react';
import { useAppStateContext } from '../AppStateContext';
import { useEffect } from 'react';
import { INewsItem } from '../../models/interfaces';

export const NewsContextValue = (): INewsContextValue => {
  const appState = useAppStateContext();
  const news = useObservable([] as INewsItem[]);

  useEffect(() => {
    loadAsync();
  }, [appState.isDealer]);

  const loadAsync = async () => {
    const newsResponse = await fetch('/api/NewsFeed.json');
    const newsData = (await newsResponse.json()) as INewsItem[];
    if (!appState.isDealer) {
      news.set(newsData.filter((x) => !x.isDealerItem));
      return;
    }

    news.set(newsData);
  };

  return { news };
};
