import { useEffect, useState } from 'react';
import { dealerUnlockService } from '../../services/DealerUnlockService';
import { IAppStateContextValue } from './IAppStateContextValue';
import { IHomeScreenData } from '../../models/interfaces';

export const AppStateContextValue = (): IAppStateContextValue => {
  const [isDealer, setIsDealer] = useState<boolean>(false);
  const [isDealerLoginModalOpen, setIsDealerLoginModalOpen] = useState<boolean>(false);
  const [homeLinks, setHomeLinks] = useState<IHomeScreenData[]>([])

  useEffect(() => {
    (async () => {
      const [isDealer, homeLinks] = await Promise.all([dealerUnlockService.getIsDealerAsync(),  (await fetch('/api/HomeLinks.json')).json()]);
      setIsDealer(isDealer);
      setHomeLinks(homeLinks as unknown as IHomeScreenData[])
    })();
  }, []);

  const tryUnlockDealerAsync = async (userName: string, password: string) => {
    const isDealer = await dealerUnlockService.tryLoginDealerAsync(userName, password);
    setIsDealer(isDealer);
    return isDealer;
  };

  const logoutDealerAsync = async () => {
    await dealerUnlockService.logoutDealerAsync();
    setIsDealer(false);
  };

  return {
    homeLinks,
    isDealer,
    tryUnlockDealerAsync,
    isDealerLoginModalOpen,
    setIsDealerLoginModalOpen,
    logoutDealerAsync,
  };
};
