import { useLoaderData } from 'react-router-dom';
import { useVideosContext } from '../contexts/VideosContext';
import { IListItem } from '../models/interfaces';
import { observer } from '@legendapp/state/react';

interface IVideoScreenProps {}
export async function loader({ params }: { params: any }) {
  return { id: params.id };
}

export const VideoScreen = observer((props: IVideoScreenProps) => {
  const loaderData: any = useLoaderData();
  const { videos } = useVideosContext();
  const videoObjs = videos.get().reduce((acc, m) => [...acc, ...m.videos], [] as IListItem[]);
  const video = videoObjs.find((m) => m.key == loaderData.id);

  if (video) {
    window.location.replace(video.Url);
    return null;
  }

  return null;
});
