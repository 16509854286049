import { createContext, useContext } from 'react';
import { AppStateContextValue } from './AppStateContextValue';
import { IAppStateContextValue } from './IAppStateContextValue';

const appStateContext = createContext<IAppStateContextValue>(undefined!);

export const useAppStateContext = () => {
  const context = useContext(appStateContext);
  if (context === undefined) {
    throw new Error('useAppStateContext must be used within a AppStateContextProvider');
  }
  return context;
};

interface IAppStateContextProps {
  children: JSX.Element;
}
export const AppStateContext = (props: IAppStateContextProps) => {
  const contextValue = AppStateContextValue();
  return <appStateContext.Provider value={contextValue}>{props.children}</appStateContext.Provider>;
};
