import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import XLogo from "./X-logo.svg";

const AppFooter: React.FC = () => {
  const iconSize = "w-6 h-6";

  return (
    <footer className="text-center text-white py-12">
      <p>&copy; StaheliWest. All rights reserved.</p>
      <div className="flex justify-center space-x-4 mt-2">
        <a
          href="https://www.facebook.com/StaheliWest"
          aria-label="Facebook"
          className={`hover:text-gray-400 ${iconSize}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook as IconProp} className={iconSize} />
        </a>
        <a
          href="https://twitter.com/StaheliWest"
          aria-label="Twitter"
          className={`hover:text-gray-400 ${iconSize}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={XLogo}
            alt="Twitter"
            className={`inline-block align-middle hover:opacity-75 transition-opacity duration-300 ${iconSize}`}
          />
        </a>
        <a
          href="https://www.instagram.com/StaheliWest"
          aria-label="Instagram"
          className={`hover:text-gray-400 ${iconSize}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faInstagram as IconProp}
            className={iconSize}
          />
        </a>
        <a
          href="https://www.youtube.com/StaheliWest"
          aria-label="YouTube"
          className={`hover:text-gray-400 ${iconSize}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube as IconProp} className={iconSize} />
        </a>
      </div>
    </footer>
  );
};

export default AppFooter;
